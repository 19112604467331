import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const ReportCenterTSX = defineComponent({
	name: "ReportCenter",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">	<path		d="M569.6 115.2L569.6 0 454.4 0l0 115.2L0 115.2l0 115.2 57.6 0 0 576 358.4 0L192 979.2 230.4 1024l275.2-224 0 0 275.2 224 38.4-44.8-217.6-179.2 364.8 0 0-576L1024 224 1024 115.2 569.6 115.2zM908.8 742.4L115.2 742.4l0-512 793.6 0L908.8 742.4zM281.6 627.2L281.6 454.4l172.8 0c0-83.2-70.4-147.2-153.6-147.2-83.2 0-153.6 70.4-153.6 153.6C153.6 544 198.4 627.2 281.6 627.2zM339.2 684.8C428.8 684.8 512 595.2 512 512L339.2 512 339.2 684.8zM793.6 339.2L627.2 339.2l0 57.6 172.8 0L800 339.2zM793.6 454.4L627.2 454.4 627.2 512l172.8 0L800 454.4zM793.6 569.6L627.2 569.6l0 57.6 172.8 0L800 569.6z"	></path></svg>
		);
	},
});

export const ReportCenter = withInstall(ReportCenterTSX);
export default ReportCenter;
