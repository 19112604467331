import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const DarkTSX = defineComponent({
	name: "Dark",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">	<path		d="M11.01 3.05C6.51 3.54 3 7.36 3 12a9 9 0 0 0 9 9c4.63 0 8.45-3.5 8.95-8 .09-.79-.78-1.42-1.54-.95A5.403 5.403 0 0 1 11.1 7.5c0-1.06.31-2.06.84-2.89.45-.67-.04-1.63-.93-1.56z"	/></svg>
		);
	},
});

export const Dark = withInstall(DarkTSX);
export default Dark;
