import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const CustomersTSX = defineComponent({
	name: "Customers",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">	<path		d="M510.59 128.93a151 151 0 1 1-106.77 44.23 150 150 0 0 1 106.77-44.23m0-64c-118.74 0-215 96.26-215 215s96.26 215 215 215 215-96.26 215-215-96.26-215-215-215zM689.4 577.63c45.17 0 88.09 19.48 120.85 54.85A195.25 195.25 0 0 1 848.69 694 207.12 207.12 0 0 1 863 770.17v62.7c0 17.93-6.33 34.64-17.83 47.05-5.93 6.4-18.61 17.15-36.9 17.15H215.74c-18.29 0-31-10.74-36.9-17.15-11.5-12.42-17.84-29.13-17.84-47.05v-62.7A207.12 207.12 0 0 1 175.31 694a195.25 195.25 0 0 1 38.44-61.51c32.76-35.37 75.67-54.85 120.85-54.85h30.86l90.61 98a76 76 0 0 0 111.48 0.15l91.23-98.16h30.62m0-64h-53.29a12 12 0 0 0-8.79 3.83L520.67 632.23a12 12 0 0 1-17.6 0L397 517.49a12 12 0 0 0-8.81-3.85H334.6C203.92 513.63 97 629.07 97 770.17v62.7c0 70.51 53.43 128.2 118.74 128.2h592.52c65.3 0 118.74-57.69 118.74-128.2v-62.7c0-141.09-106.92-256.53-237.6-256.53z"	></path></svg>
		);
	},
});

export const Customers = withInstall(CustomersTSX);
export default Customers;
